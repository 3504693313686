import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import GlobalState from "../GlobalState";

export default function Layout(props) {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [headerScroll, setHeaderScroll] = useState(false);
    const { fixedTitles, global } = useContext(GlobalState);

    function languageClicked(x) {
        localStorage.setItem("language", x);
        window.location.reload();
    }

    function menuClick() {
        setMobileMenuOpen(true);
    }

    function closeMenu() {
        setMobileMenuOpen(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', function (e) {
            setHeaderScroll(window.pageYOffset > 50);
        });
    }, []);

    return (
        <>
            {fixedTitles ?
                <>

                    <div className="navbar-items container-fluid header-scroll">
                        <div className={"row align-items-end header-scroll" + (headerScroll ? " py-lg-2 py-md-2 py-sm-2 py-2 mx-lg-3 mx-md-2" : " py-lg-3 py-md-2 py-sm-2 py-2 mx-lg-3 mx-md-2")}>
                            <div className="col">
                                <Link to="/">
                                    {
                                        props.whiteNav ?
                                            <img className="logo " src="/assets/images/white-logo-new.svg" alt="logo" />
                                            :
                                            <img className="logo fixed" src="/assets/images/logo.svg" alt="logo" />
                                    }
                                </Link>
                            </div>
                            <div className="col-auto d-lg-block d-none">
                                <div className="row">
                                    <div className="col justify-content-end d-flex">
                                        <div className="d-flex align-items-center languages mb-2">
                                            {
                                                props?.hasEnglish || props?.hasArabic ?
                                                    null
                                                    :
                                                    <>
                                                        <h3 className={"mb-0 menu" + (localStorage.getItem("language") === 'en' ? " active" : "")} onClick={() => languageClicked('en')}>{fixedTitles.english}</h3>
                                                        <span className="mx-1">|</span>
                                                        <h3 className={"mb-0 menu" + (localStorage.getItem("language") === 'ar' ? " active" : "")} onClick={() => languageClicked('ar')}>{fixedTitles.arabic}</h3>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex">
                                        <div className="me-md-5">
                                            <Link className={"menu " + (props.activePage === "about" ? " active" : "") + (props.activePage === "about" && headerScroll ? "header-scroll active" : "")} to="/about">{fixedTitles.about_us}</Link>
                                        </div>
                                        <div className="me-md-5">
                                            <Link className={"menu " + (props.activePage === "services" ? " active" : "") + (props.activePage === "services" && headerScroll ? "header-scroll active" : "")} to="/services">{fixedTitles.services}</Link>
                                        </div>
                                        <div className="me-md-5">
                                            <Link className={"menu " + (props.activePage === "insights" ? " active" : "") + (props.activePage === "insights" && headerScroll ? "header-scroll active" : "")} to="/insights">{fixedTitles.insights}</Link>
                                        </div>
                                        {/* <div className="me-md-5">
                                            <Link className={"menu " + (props.activePage === "publications" ? " active" : "") + (props.activePage === "publications" && headerScroll ? "header-scroll active" : "")} to="/publications">{fixedTitles.publications}</Link>
                                        </div> */}
                                        <div className="">
                                            <Link className={"menu " + (props.activePage === "contact" ? " active" : "") + (props.activePage === "contact" && headerScroll ? "header-scroll active" : "")} to="/contact">{fixedTitles.contact}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-auto d-block d-lg-none" onClick={menuClick}>
                                <svg className="burger-icon" xmlns="http://www.w3.org/2000/svg" width="22.664" height="14.449" viewBox="0 0 22.664 14.449">
                                    <g id="Group_979" data-name="Group 979" transform="translate(-313.586 -8.75)">
                                        <g id="Group_947" data-name="Group 947" transform="translate(-20 -20)">
                                            <line className="burger-color" id="Line_202" data-name="Line 202" x2="20.164" transform="translate(334.836 30)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2.5" />
                                            <line className="burger-color" id="Line_203" data-name="Line 203" x2="20.164" transform="translate(334.836 35.975)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2.5" />
                                            <line className="burger-color" id="Line_204" data-name="Line 204" x2="20.164" transform="translate(334.836 41.949)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2.5" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className={" mobile-menu" + (mobileMenuOpen ? "" : " hide")}>

                        <div className="container-fluid h-100">

                            <div className="text-end close-mobile-menu" onClick={closeMenu}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12.338 12.338">
                                    <line id="Line_35" data-name="Line 35" y2="13.448" transform="translate(10.924 1.414) rotate(45)" fill="none" stroke="#414042" strokeLinecap="round" strokeWidth="1.5"></line>
                                    <line id="Line_36" data-name="Line 36" y2="13.448" transform="translate(1.414 1.414) rotate(-45)" fill="none" stroke="#414042" strokeLinecap="round" strokeWidth="1.5"></line>
                                </svg>
                            </div>

                            <div className="row align-items-center justify-content-center text-center pt-5">
                                <div className="col d-block">
                                    <div className="mb-4">
                                        <Link className={"menu" + (props.activePage === "home" ? " active" : "")} to="/">{fixedTitles.home}</Link>
                                    </div>
                                    <div className="mb-4">
                                        <Link className={"menu" + (props.activePage === "about" ? " active" : "")} to="/about">{fixedTitles.about_us}</Link>
                                    </div>
                                    <div className="mb-4">
                                        <Link className={"menu" + (props.activePage === "services" ? " active" : "")} to="/services">{fixedTitles.services}</Link>
                                    </div>
                                    <div className="mb-4">
                                        <Link className={"menu" + (props.activePage === "insights" ? " active" : "")} to="/insights">{fixedTitles.insights}</Link>
                                    </div>
                                    {/* <div className="mb-4">
                                        <Link className={"menu" + (props.activePage === "publications" ? " active" : "")} to="/publications">{fixedTitles.publications}</Link>
                                    </div> */}
                                    <div className="mb-4">
                                        <Link className={"menu" + (props.activePage === "contact" ? " active" : "")} to="/contact">{fixedTitles.contact}</Link>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center languages mb-2">
                                        <h3 className={"mb-0 menu" + (localStorage.getItem("language") === 'en' ? " active" : "")} onClick={() => languageClicked('en')}>{fixedTitles.english}</h3>
                                        <span className="mx-1">|</span>
                                        <h3 className={"mb-0 menu" + (localStorage.getItem("language") === 'ar' ? " active" : "")} onClick={() => languageClicked('ar')}>{fixedTitles.arabic}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </>
                :
                null}

            {props.children}

            {global ?
                <div className="footer">

                    <hr className="seperator m-0" />
                    {fixedTitles ?
                        <div className="container-fluid py-5" animate="up">
                            <div className="text-center">
                                <div className="">
                                    <div className="section-2" onClick={() => animateScroll.scrollTo(0, 0)}>
                                        <div className="container-fluid">
                                            <div className="text-end mx-lg-3">
                                                <img className="arrow-down up" src="/assets/images/footer-arrow.svg" alt="video" />
                                            </div>
                                        </div>
                                    </div>
                                    <h1 >{fixedTitles.follow_us}</h1>
                                </div>
                                <div className="pb-5">
                                    {
                                        global.social_media.map((socialIcon, index) =>
                                            <a target="_blank" rel="noreferrer" href={socialIcon.social_url} key={index}>
                                                <img className="social-icon mx-md-3 mx-2" src={socialIcon.social_icon} alt="social-icon" />
                                            </a>
                                        )

                                    }

                                </div>
                                <div className="row ">
                                    <div className="col justify-content-center d-md-flex d-block">
                                        <div className="me-md-5 mb-sm-3 mb-3">
                                            <Link className={"menu" + (props.activePage === "about" ? " active" : "")} to="/about">{fixedTitles.about_us}</Link>
                                        </div>
                                        <div className="me-md-5 mb-sm-3 mb-3">
                                            <Link className={"menu" + (props.activePage === "services" ? " active" : "")} to="/services">{fixedTitles.services}</Link>
                                        </div>
                                        <div className="me-md-5 mb-sm-3 mb-3">
                                            <Link className={"menu" + (props.activePage === "insights" ? " active" : "")} to="/insights">{fixedTitles.insights}</Link>
                                        </div>
                                        <div className="">
                                            <Link className={"menu" + (props.activePage === "contact" ? " active" : "")} to="/contact">{fixedTitles.contact}</Link>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-0">{fixedTitles.copy_right}</p>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                :
                null}

        </>

    )
}