import { useContext } from "react";
import Layout from "../components/Layout";
import GlobalState from "../GlobalState";
import { QueryClient, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import Publication from "../components/Publication";
import { Helmet } from "react-helmet";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const queryClient = new QueryClient();

export default function SinglePublication() {
  const { fixedTitles, global } = useContext(GlobalState);
  const params = useParams();

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["publication", params?.slug],
    queryFn: () =>
      axios.get("/publications/" + params.slug).then((res) => res.data),
  });

  console.log(data);

  const singlePublication = data?.page_items;

  return data && fixedTitles ? (
    <Layout
      activePage="publications"
      hasEnglish={singlePublication?.show_only_english}
      hasArabic={singlePublication?.show_only_arabic}
    >
      <Helmet>
        <title>{singlePublication.seo_title}</title>
        <meta name="title" content={singlePublication.seo_title} />
        <meta name="description" content={singlePublication.seo_description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location} />
        <meta property="og:title" content={singlePublication.seo_title} />
        <meta
          property="og:description"
          content={singlePublication.seo_description}
        />
        <meta property="og:image" content={singlePublication.seo_image} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location} />
        <meta property="twitter:title" content={singlePublication.seo_title} />
        <meta
          property="twitter:description"
          content={singlePublication.seo_description}
        />
        <meta property="twitter:image" content={singlePublication.seo_image} />
      </Helmet>
      <div className="single-pub-wrapper">
        <div className="container-fluid py-5">
          <div className="row mx-lg-3 mx-md-2">
            <div className="col-md-7">
              <div className="mb-3 mb-lg-0">
                <p className="pub-single-date">
                  {singlePublication?.formatted_date}
                </p>
                <p className="pub-single-title">{singlePublication?.title}</p>
                <div
                  className="pub-single-description mb-4"
                  dangerouslySetInnerHTML={{
                    __html: singlePublication?.first_description,
                  }}
                ></div>
              </div>
            </div>
            <div className="col-md-5 d-flex align-items-start justify-content-center">
              <div
                className=""
                style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                <img
                  src={singlePublication?.image_path}
                  alt="publication"
                  className="single-pub-img"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div
                className="h-100 d-none"
                style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                <img
                  src={singlePublication?.image_path}
                  alt="publication"
                  className="w-100 h-100"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-12 mt-4">
              <div
                className="pub-single-description mb-4"
                dangerouslySetInnerHTML={{
                  __html: singlePublication?.second_description,
                }}
              ></div>

              {singlePublication?.pdf_file ? (
                <a
                  href={singlePublication?.pdf_path}
                  download
                  target="_blank"
                  rel="noreferrer"
                  className="pub-download-pdf "
                >
                  {fixedTitles?.download_pdf}
                </a>
              ) : (
                <></>
              )}
            </div>
          </div>

          {singlePublication.publications?.length ? (
            <>
              <div
                className="col-12 d-flex justify-content-center"
                style={{ marginTop: "75px" }}
              >
                <p className="more-publications">
                  {fixedTitles.more_publications}
                </p>
              </div>
              <div className="position-relative">
                <div className="container-fluid   pb-5 mb-lg-5">
                  <div className="mx-lg-3 mx-md-2">
                    <div className="justify-content-end d-flex pb-4">
                      <div className="swiper-button-prev  me-5">
                        <img src="/assets/images/prev.svg" alt="prev" />
                      </div>
                      <div className="swiper-button-next ">
                        <img src="/assets/images/next.svg" alt="next" />
                      </div>
                    </div>
                    <Swiper
                      spaceBetween={15}
                      slidesPerView={1}
                      modules={[Navigation]}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      breakpoints={{
                        1199.98: {
                          slidesPerView: 4,
                        },

                        991.98: {
                          slidesPerView: 3,
                        },

                        // when window width is >= 767.98px
                        767.98: {
                          slidesPerView: 3,
                        },
                        575.98: {
                          slidesPerView: 2,
                        },
                      }}
                    >
                      {[...singlePublication?.publications].map(
                        (publication, index) => (
                          <SwiperSlide key={index}>
                            <Publication
                              fixedTitles={fixedTitles}
                              publication={publication}
                            />
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </div>
                </div>
                <div className="bg-single-services">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100.37"
                    height="103.729"
                    viewBox="0 0 192.37 103.729"
                  >
                    <path
                      id="Path_3"
                      data-name="Path 3"
                      d="M1727.629,3351.714l3.848,7.71,188.522-94.1v-9.632Z"
                      transform="translate(-1727.629 -3255.695)"
                      fill="#80808061"
                    />
                  </svg>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout>
  ) : (
    <></>
  );
}
